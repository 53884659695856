import React from "react";
import classNames from "classnames";
import { Container, Text, Button, ButtonAlt, Image } from "@atoms";
import useDefaultsData from "@staticQueries/DefaultsQuery";

const EopCta = ({ bgColor, otherCta, lessPadding }) => {
  const { cta } = useDefaultsData();
  const { heading, descriptor, button, link, image } = otherCta?.length
    ? otherCta
    : cta;

  const background = {
    white: "bg-white",
    offWhite: "bg-offwhite",
    lighterTeal: "bg-teal-lighter",
    lightTeal: "bg-teal-light",
    darkTeal: "bg-teal-dark",
    black: "bg-black",
  }[bgColor];

  return (
    <section
      className={classNames("relative py-10 sm:pt-20", background, {
        "sm:pb-20": !lessPadding,
      })}
    >
      <Container>
        <div className="relative z-10 flex gap-6 rounded-lg bg-pink p-4 text-white shadow-lg sm:p-6 md:p-8 lg:p-12 xl:p-16">
          <div className="flex w-full flex-shrink-0 flex-col gap-6 sm:w-3/5">
            <Text variant="h2">{heading}</Text>
            <Text variant="xl">{descriptor}</Text>
            <div className="flex flex-wrap gap-5 text-white">
              {!!button?.url && (
                <Button color="white" to={button.url}>
                  {button.text}
                </Button>
              )}
              {!!link?.url && (
                <ButtonAlt to={link.url} color="pink">
                  {link.text}
                </ButtonAlt>
              )}
            </div>
          </div>
          {image && (
            <div className="-my-2 w-full sm:-my-3 md:-my-4 lg:-my-5 xl:-my-8">
              <Image
                className="overflow-hidden rounded-full"
                image={image}
                aspectRatio={[1, 1]}
              />
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

EopCta.defaultProps = {
  bgColor: "white",
};

export default EopCta;
